@charset "UTF-8";

// 主色调
$main-color: #d80a16;
// 辅色调
$vice-color: #B30811;
// 标题字体颜色
$main-font-color: #333333;
// 辅助字体颜色
$vice-font-color: #666666;
// 提示字体颜色
$tip-font-color: #999999;
// 鼠标悬浮背景颜色
$hover-back-color: #eef4ff;