@charset "UTF-8";
@import "../base/base";

.index-con {
    font-size: 0;
    // 头条资讯
    .headlines {
        width: 577.5px;
        margin: 10px;
        display: inline-block;
        vertical-align: top;
        background-color: #ffffff;

        .headlines-con {
            padding-bottom: 30px;

            .headlines-con-con {
                cursor: pointer;
                padding-bottom: 15px;
                background-color: $hover-back-color;

                .pic {
                    width: 100%;
                    height: 298px;
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center;
                }

                .title {
                    font-size: 16px;
                    margin: 10px 0;
                    font-weight: bolder;
                    color: $main-font-color;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    padding: 10px;
                }

                .details {
                    padding: 0 10px;
                    font-size: 14px;
                    color: $vice-font-color;
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                }
            }

            .swiper-pagination-bullet{
                width: 12px;
                height: 12px;
            }

            .swiper-pagination-bullet-active {
                background: $vice-color;
            }
        }
    }

    // 行业动态
    .industry {
        width: 380px;
        height: 400px;
        margin: 10px;
        display: inline-block;
        vertical-align: top;
        background-color: #ffffff;

        .industry-con {
            .list {
                width: 100%;
                padding-bottom: 15px;

                .list-top {
                    padding-bottom: 15px;

                    .title {
                        font-size: 16px;
                        margin-bottom: 10px;
                        font-weight: bolder;
                        color: $main-font-color;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        padding: 10px;
                    }

                    .details {
                        padding: 0 10px;
                        font-size: 14px;
                        display: -webkit-box;
                        color: $vice-font-color;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                    }
                }

                .list-other {
                    font-size: 14px;
                    position: relative;
                    border-top: 1px dashed #cccccc;

                    span {
                        display: block;
                        width: 100%;
                        height: 100%;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        color: $main-font-color;
                        padding: 8px 20px;
                    }

                    &:before {
                        content: '';
                        width: 8px;
                        height: 8px;
                        background-color: $main-color;
                        position: absolute;
                        top: 50%;
                        left: 0;
                        transform: rotate(45deg) translateY(-50%);
                    }

                    &:hover {
                        background-color: $hover-back-color;
                    }
                }

            }
        }
    }

    // 通知公告
    .notice {
        width: 380px;
        height: 400px;
        margin: 10px;
        display: inline-block;
        vertical-align: top;
        background-color: #ffffff;

        .notice-con {
            .time {
                display: inline-block;
                vertical-align: top;
                padding: 0 10px;
                text-align: center;
                font-size: 25px;
                font-weight: bold;
                color: $main-font-color;

                p {
                    font-size: 12px;
                    color: $vice-font-color;
                }
            }

            .con {
                width: 280px;
                display: inline-block;
                vertical-align: top;
                font-size: 14px;
                color: $main-font-color;
                position: relative;

                div {
                    height: 66px;
                    padding: 0 15px 22px 15px;
                    p{
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                    }
                }

                &:before {
                    content: '';
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    background-color: $main-color;
                    position: absolute;
                    top: 0;
                    left: 0;
                    transform: translateX(-50%);
                    z-index: 2;
                }

                &:after {
                    content: '';
                    width: 1px;
                    height: 100%;
                    border-left: 1px solid #cccccc;
                    position: absolute;
                    top: 0;
                    left: 0;
                }
            }

            &:last-child {
                .con {
                    &:after {
                        border-color: transparent;
                    }
                }
            }
        }
    }

    // 热点聚焦
    .hot {
        width: 780px;
        height: 480px;
        margin: 10px;
        display: inline-block;
        vertical-align: top;
        background-color: #ffffff;

        .hot-con {
            width: 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;

            .hot-con-con {
                width: 185px;
                padding: 10px;
                display: inline-block;
                vertical-align: top;

                .pic {
                    width: 100%;
                    height: 123px;
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: center;
                    margin-bottom: 10px;
                    transition: all 0.2s;
                }

                .title {
                    color: $main-font-color;
                    font-size: 14px;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                }

                &:hover {
                    background-color: $hover-back-color;
                }
            }
        }
    }

    // 入会指南
    .initiation {
        width: 577.5px;
        margin: 10px;
        display: inline-block;
        vertical-align: top;
        background-color: #ffffff;

        .initiation-con {
            width: 100%;
            padding: 0 20px;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;

            .initiation-con-con {
                width: 150px;
                margin-bottom: 10px;
                padding: 37px 0;
                text-align: center;
                background-color: $hover-back-color;

                .pic {
                    height: 40px;

                    img {
                        height: 100%;
                    }
                }

                .title {
                    height: 20px;
                    line-height: 20px;
                    font-size: 14px;
                    color: $main-font-color;
                    font-weight: bolder;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }

            .other-initiation-con-con {
                width: 100%;
            }
        }
    }

    // 政策文件
    .file {
        width: 577.5px;
        height: 375px;
        margin: 10px;
        display: inline-block;
        vertical-align: top;
        background-color: #ffffff;

        .file-con {


            .list {
                width: 100%;
                padding-bottom: 15px;

                .list-top {
                    padding-bottom: 15px;

                    .title {
                        font-size: 16px;
                        margin: 10px 0;
                        font-weight: bolder;
                        color: $main-font-color;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        padding: 10px;
                    }

                    .details {
                        padding: 0 10px;
                        font-size: 14px;
                        display: -webkit-box;
                        color: $vice-font-color;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                    }
                }

                .list-other {
                    font-size: 14px;
                    position: relative;
                    border-top: 1px dashed #cccccc;

                    span {
                        display: block;
                        width: 470px;
                        height: 100%;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        color: $main-font-color;
                        padding: 8px 20px;
                    }

                    label {
                        display: inline-block;
                        font-size: 12px;
                        color: $tip-font-color;
                        position: absolute;
                        right: 10px;
                        top: 50%;
                        transform: translateY(-50%);
                    }

                    &:before {
                        content: '';
                        width: 8px;
                        height: 8px;
                        background-color: $main-color;
                        position: absolute;
                        top: 50%;
                        left: 0;
                        transform: rotate(45deg) translateY(-50%);
                    }

                    &:hover {
                        background-color: $hover-back-color;
                    }
                }
            }
        }
    }

    // 广告位轮播
    .indexAds {
        width: 1180px;
        padding: 0;
        margin: 10px auto;

        img {
            width: 100%;
        }
    }

    // 协会大记事
    .events {
        width: 780px;
        height: 287px;
        margin: 10px;
        display: inline-block;
        vertical-align: top;
        background-color: #ffffff;

        .events-con {
            width: 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;

            .events-con-con {
                width: 185px;
                padding: 10px;
                display: inline-block;
                vertical-align: top;

                .pic {
                    width: 100%;
                    height: 123px;
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: center;
                    margin-bottom: 10px;
                    transition: all 0.2s;
                }

                .title {
                    color: $main-font-color;
                    font-size: 14px;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                }

                &:hover {
                    background-color: $hover-back-color;
                }
            }
        }
    }

    // 下载专区、会员动态
    .download {
        width: 380px;
        margin: 10px;
        display: inline-block;
        vertical-align: top;
        background-color: #ffffff;

        .download-con {
            .details {
                padding: 0 10px;
                font-size: 14px;
                display: -webkit-box;
                color: $vice-font-color;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
            }

            .list {
                width: 100%;
                padding: 5px 0;

                li {
                    font-size: 14px;
                    position: relative;
                    border-top: 1px dashed #cccccc;

                    span {
                        display: block;
                        width: 280px;
                        height: 100%;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        color: $main-font-color;
                        padding: 8px 20px;
                    }

                    label {
                        display: inline-block;
                        font-size: 12px;
                        color: $tip-font-color;
                        position: absolute;
                        right: 10px;
                        top: 50%;
                        transform: translateY(-50%);
                    }

                    &:before {
                        content: '';
                        width: 8px;
                        height: 8px;
                        background-color: $main-color;
                        position: absolute;
                        top: 50%;
                        left: 0;
                        transform: rotate(45deg) translateY(-50%);
                    }

                    &:hover {
                        background-color: $hover-back-color;
                    }
                }
            }

            .credit {
                padding-top: 20px;

                .credit-con {
                    .credit-con-list {
                        width: 100%;
                        height: 105px;

                        .credit-con-list-wrapper {
                            .credit-con-list-slide {
                                height: 35px;

                                li {
                                    font-size: 14px;
                                    position: relative;
                                    border-top: 1px dashed #cccccc;

                                    .credit-con-list-title {
                                        display: inline-block;
                                        vertical-align: middle;
                                        width: 250px;
                                        overflow: hidden;
                                        white-space: nowrap;
                                        text-overflow: ellipsis;
                                        color: $main-font-color;
                                        padding: 8px 20px;
                                        font-size: 14px;
                                    }

                                    .credit-con-list-start {
                                        display: inline-block;
                                        vertical-align: middle;
                                        color: $tip-font-color;

                                        .iconfont {
                                            font-size: 14px;
                                            color: #d80a16;
                                        }
                                    }

                                    &:before {
                                        content: '';
                                        width: 8px;
                                        height: 8px;
                                        background-color: $main-color;
                                        position: absolute;
                                        top: 50%;
                                        left: 0;
                                        transform: rotate(45deg) translateY(-50%);
                                    }

                                    &:hover {
                                        background-color: $hover-back-color;
                                    }

                                }
                            }
                        }
                    }
                }
            }

        }

        .swiper-pagination-bullet-active {
            background: $vice-color;
        }
    }

    .dynamic {
        height: 470px;
    }

    // 会员展示
    .members {
        width: 780px;
        height: 470px;
        margin: 10px;
        display: inline-block;
        vertical-align: top;
        background-color: #ffffff;

        .members-con {
            .members-con-con {
                margin: 10px 0;

                .left {
                    width: 165px;
                    padding: 10px 0;
                    text-align: center;
                    font-size: 14px;
                    font-weight: bolder;
                    color: $main-font-color;
                    background-color: $hover-back-color;
                    display: inline-block;
                    vertical-align: top;

                    .pic {
                        height: 30px;
                        margin-bottom: 5px;

                        img {
                            height: 100%;
                        }
                    }
                }

                .right {
                    display: inline-block;
                    vertical-align: top;
                    width: 585px;
                    color: $main-font-color;

                    .right-con {
                        text-align: left;

                        .con-con {
                            padding: 0 50px;
                            font-size: 0;

                            span {
                                width: calc(100% / 3 - 10px);
                                font-size: 14px;
                                display: inline-block;
                                margin: 5px 10px 5px 0;
                                overflow: hidden;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                            }
                        }

                        .prev, .next {
                            display: none;
                            background-size: 100%;
                            background-repeat: no-repeat;
                            background-position: center center;

                            &:after {
                                content: '';
                            }
                        }

                        .prev {
                            background-image: url("/static/images/index/prev.png");
                        }

                        .next {
                            background-image: url("/static/images/index/next.png");
                        }
                    }
                }
            }
        }
    }

    // 民校之窗
    .school {
        width: 1180px;
        height: 250px;
        margin: 10px;
        display: inline-block;
        vertical-align: top;
        background-color: #ffffff;

        .school-con {
            width: 100%;

            .school-con-con {
                .con-slide {
                    width: 185px;
                    padding: 0 10px;
                    display: inline-block;
                    vertical-align: top;

                    .pic {
                        width: 100%;
                        height: 123px;
                        background-size: contain;
                        background-repeat: no-repeat;
                        background-position: center;
                        margin-bottom: 10px;
                        transition: all 0.2s;
                    }

                    .title {
                        color: $main-font-color;
                        font-size: 14px;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                    }
                }
            }

            .prev, .next {
                height: 100%;
                position: absolute;
                top: 0;
                background-size: 100%;
                background-repeat: no-repeat;
                background-position: center center;

                &:after {
                    content: '';
                }

                &:hover {
                    background-color: rgba(0, 0, 0, 0.8);
                }
            }

            .prev {
                background-image: url("/static/images/index/prev.png");
            }

            .next {
                background-image: url("/static/images/index/next.png");
            }
        }
    }
}
